<template>
    <div class="order">
        <titleNav title="灵活就业订单" :left-arrow="true" left-text="" />
        <div class="topbacc">
            <div>灵活就业订单</div>
        </div>
        <div class="lists">
            <div class="ord" v-for="(item,index) in datas" :key="index">
                <div class="name">{{item.true_name}}</div>
                <div class="time">{{item.time}}</div>
                <div class="pid">{{item.true_num}}</div>
                <button @click="details(item.id)">查看详情</button>
            </div>
        </div>
    </div>
</template>

<script>
import titleNav from "@/components/conetnts/titleNav.vue";
import { Button, Dialog, Icon, Toast, NavBar, Swipe, SwipeItem } from "vant";
import { list } from "@/network/index.js";
export default {
  components: {
    titleNav,
    [Button.name]: Button,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
      return{
          datas:[],
      }
  },
  mounted(){
      this.list();
  },
  methods:{
      list(){
          let userid = sessionStorage.getItem("id");
          let data={
              userid
          }
          list(data).then((res)=>{
              this.datas=res.data.data
          })
      },
      details(e){
          let id=e
          this.$router.push("/orderdetails?id="+id);
      },
  }
}
</script>

<style scoped lang="scss">
.order{
    .topbacc{
        width: 100%;
        height: 100px;
        background-color: #0f81fd;
        div{
            color: #ffffff;
            font-size: 20px;
            padding-top: 15px;
            padding-left: 4vw;
        }
    }
    .lists{
        margin-top: -50px;
        margin-left: 4vw;
        width: 92vw;
        background-color: rgb(255, 255, 255);
        // padding-top: 15px;
        .ord{
            padding-top: 15px;
            width: 84vw;
            height: 110px;
            margin-left: 4vw;
            border-bottom: 1px solid #eee;
            .name{
                font-size: 20px;
                font-weight: 700;
            }
            .time{
                margin-top: 10px;
                font-size: 18px;
            }
            .pid{
                margin-top: 10px;
                font-size: 18px;
                float: left;
            }
            button{
                font-size: 20px;
                background-color: #0f81fd;
                color: #ffffff;
                border: 0;
                width:100px;
                height: 30px;
                border-radius: 15px;
                float: right;
            }
        }
    }
}
</style>